@import 'variables.module.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';

@import '../node_modules/swiper/modules/navigation/navigation.scss';
@import '../node_modules/swiper/modules/pagination/pagination.scss';
@import '../node_modules/swiper/swiper.scss';
@import '../node_modules/swiper/modules/scrollbar/scrollbar.scss';
// Effects
@import '../node_modules/swiper/modules/effect-flip/effect-flip.scss';
@import '../node_modules/swiper/modules/effect-coverflow/effect-coverflow.scss';


// Font-Definitions
@font-face {
  font-family: "Raleway";
  src: url('../public//fonts/Raleway-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  }
  @font-face {
    font-family: "Raleway";
    src: url('../public//fonts/Raleway-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    }
  @font-face {
  font-family: "Raleway";
  src: url('../public//fonts/Raleway-Bold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  }

  @font-face {
    font-family: "BlackJack";
    src: url('../public/fonts/blackjack.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    }
  
  // Override Bootstrap Variables
  :root {
    --bs-font-sans-serif: Raleway;
  }

  

html{
  overflow-x: hidden;
}
body{
  // font-family: $font-stack;
  font-family: 'IBMPlexSans !important';
  margin:auto;
  display:flex; 
  flex-direction:column; 
  min-height: 100vh;
  min-width: 400px;
  overflow-x: hidden;
  padding-right: 0px !important;
}
img {
  pointer-events: none;
}
.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}

.primaryHeadingColor{
  color: $h-text-primary;
}

.webpageTitle{
  background-color: red;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  h2{
    font-weight:300 !important;
    font-size:1.5em !important;
  }
}


.swiper-button-prev, .swiper-button-next {
  top: 45%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  outline: 0;
  transition: background-color .2s ease, color .2s ease;
  
  &::after {
      font-size: 16px;
  }
}

.swiper-button-prev {
  &:after {
      position: relative;
      left: -1px;
  }
}

.swiper-button-next {
  &:after {
      position: relative;
      left: 1px;
  }
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

video{
  height: 100%;
  width: auto;
}

// Button-Design
.btn-primary {
  color: #fff;
  background-color: $initial_button_primary_color !important;
  border-color: $initial_button_border_primary_color !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: yellow;
  border-color: #0d6efd;
}
// Button-Design Secondary variant
.btn-secondary {
  color: #fff;
  background-color: rgba(18, 70, 80, 1)
  // border-color: #0d6efd;
}
.btn-secondary:hover {
  color: #fff;
  background-color: rgba(18, 70, 80, 0.8)
  // border-color: #0d6efd;
}
.navbar-toggler-icon{
  background-image: none !important;
  width: 0.5em !important;
}

table{
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
thead tr {
  background-color: #3d3d3d;
  color: #ffffff;
  text-align: left;
}
table > tbody > tr > td{
  padding: 12px 15px;
}
table > thead > tr > th {
  padding: 12px 15px;
}
tbody tr {
  border-bottom: thin solid #dddddd;
}
tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
tbody tr:last-of-type {
  border-bottom: 2px solid #3d3d3d;
}
tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.general-md{
  p{
    font-family: Raleway,sans-serif;
    font-size: 1em;
    line-height: 26px;
    color: #818181 !important;
    font-weight: 300;
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    padding-bottom: 10px;
  }
  h1 {
    font-size: 1.5em;
    line-height: 26px;
    text-transform: none;
    color: #9a5ba5 !important;
    padding-bottom: 10px;
    font-family: Raleway,sans-serif;
}
  h2 {
    font-size: 1.3em;
    line-height: 26px;
    text-transform: none;
    color: #9a5ba5 !important;
    padding-bottom: 10px;
    font-family: Raleway,sans-serif;
}
    h3 {
      font-size: 1em;
      line-height: 26px;
      text-transform: none;
      color: #9a5ba5 !important;
      padding-bottom: 10px;
      font-family: Raleway,sans-serif;
  }
  li{
    color: #818181 !important;
    font-weight: 300;
  }
}
.general-md-justified{
  p{
    font-family: Raleway,sans-serif;
    font-size: 1em;
    line-height: 26px;
    color: #818181 !important;
    font-weight: 300;
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    padding-bottom: 10px;
    text-align: justify;
  }
  h1 {
    font-size: 1.5em;
    line-height: 26px;
    text-transform: none;
    color: #6a2478!important;
    padding-bottom: 10px;
    font-family: 'BlackJack';
}
  h2 {
    font-size: 1.3em;
    line-height: 26px;
    text-transform: none;
    color: #6a2478 !important;
    padding-bottom: 10px;
    font-family: 'BlackJack';
}
    h3 {
      font-size: 1em;
      line-height: 26px;
      text-transform: none;
      color: #6a2478 !important;
      padding-bottom: 10px;
      font-family: 'BlackJack';
  }
  li{
    color: #818181 !important;
    font-weight: 300;
  }
}

.heading-uppercase{
  text-transform: uppercase;
}

.iconDiv{
  z-Index:100010;
}
.iconDiv svg {
  display: inline-block;
  margin: auto;
  text-align: center;
  z-Index:100010;
}