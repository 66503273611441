$font-stack: OpenSans !important;
// $font-stack: "--apple-system", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$primary: #000;
$secondary-color: #efefef;
$tertiary-color: #d32721;
$white: #fff;
$black: #000;
$dark: #fff;
$light: #fff;
$bg-container: #fff;
$blue4: #fff;
//Button-Test
$btn-primary: #6a2478;
$initial_button_primary_color: rgb(106, 36, 120);
$initial_button_border_primary_color: rgb(106, 36, 120);

// Standard-Links
$initial-primary-link-color:rgba(106, 36, 120, 1);
$initial-primary-link-hover-color:rgba(106, 36, 120, 0.8);



// Footer
$initial-footer-logo-width:250px;
$initial-footer-logo-height:120px;
$initial-footer-background-color: #000000;
$initial-i-color: #ffffff;
$initial-p-color: #ffffff;
$initial-h1-color: rgb(106, 36, 120);
$initial-h1-size: 20px;
$initial-h2-color: rgb(106, 36, 120);
$initial-h2-size: 18px;
$initial-h3-color: rgb(106, 36, 120);
$initial-h3-size: 16px;
$initial-h4-color: rgb(106, 36, 120);
$initial-h4-size: 14px;
//Footer & Header Social Link Styling
$initial-social-link-bg-color: #6a2478;
$initial-social-link-bg-hovering-color: rgba(154, 91, 165, 0.8);
$initial-social-link-icon-color: white;
$initial-social-link-icon-hovering-color: white;
//Social Links on Blogs Detail Page 
$initial-social-link-blog-page-icon-color: #6a2478;
$initial-social-link-blog-page-icon-hovering-color: rgba(154, 91, 165, 0.8);
// BackToTopButton
$initial-backtotop-bg-color: #6a2478;
//Lower-Footer Link Styling Animation
$initial-lower-footer-a-color: #051c2c;
$initial-lower-footer-h6-color: #051c2c;
$initial-lower-footer-background-color: rgba(188,143,51,1);
$bg: #051c2c;
$color: hsl(0, 0%, 100%);
$underline-width: 1px;
$underline-block-width: 20px;
$underline-color: #051c2c;
$underline-color-hover: rgb(214, 169, 79);
$underline-transition: 0.5s;

$h-text-primary: #6a2478;

//Header Styling
$initial-header-background-color: #bc8f33;

//Navbar - Menu styling
$initial-navbar-link-color: rgba(154, 91, 165, 1);
$initial-navbar-link-color-hover: rgba(154, 91, 165, 1);
$initial-navbar-dropdown-title-link-color: rgba(154, 91, 165, 1);
$initial-navbar-dropdown-link-color: rgba(154, 91, 165, 1);
$initial-navbar-dropdown-link-color-hover: rgba(154, 91, 165, 1);
$initial-navbar-dropdown-link-color-focus: #ffffff;
$initial-navbar-dropdown-link-background-color-hover:#ffffff;

// Standard Heading 
// $initial-standardheaderbanner-background-color:rgba(154, 91, 165, 1);
$initial-standardheaderbanner-background-color:rgb(193, 150, 196);
$initial-standardheaderbanner-font-color:#ffffff;

:export {
  initial_footerLogoWidth: $initial-footer-logo-width;
  initial_footerLogoHeight: $initial-footer-logo-height;
  initial_footerBackgroundColor: $initial-footer-background-color;
  initial_iColor: $initial-i-color;
  initial_pColor: $initial-p-color;
  initial_h1Color: $initial-h1-color;
  initial_h1Size: $initial-h1-size;
  initial_h2Color: $initial-h2-color;
  initial_h2Size: $initial-h2-size;
  initial_h3Color: $initial-h3-color;
  initial_h3Size: $initial-h3-size;
  initial_h4Color: $initial-h4-color;
  initial_h4Size: $initial-h4-size;
  initial_socialLinkBgHoveringColor: $initial-social-link-bg-hovering-color;
  initial_socialLinkIconColor: $initial-social-link-icon-color;
  initial_socialLinkIconHoveringColor: $initial-social-link-icon-hovering-color;
  initial_lowerFooter_aColor: $initial-lower-footer-a-color;
  initial_lowerFooter_h6Color: $initial-lower-footer-h6-color;
  initial_lowerFooter_backgroundColor: $initial-lower-footer-background-color;
  initial_backToTop_backgroundColor: $initial-backtotop-bg-color;
  initial_standardHeaderBanner_backgroundColor: $initial-standardheaderbanner-background-color;
  initial_standardHeaderBanner_fontColor: $initial-standardheaderbanner-font-color;
  initial_header_BackgroundColor: $initial-header-background-color;

  initial_navbar_linkColor: $initial-navbar-link-color;
  initial_button_primary_color: $initial_button_primary_color;
  initial_button_border_primary_color: $initial_button_border_primary_color;

  initial_h_text_primary_color: $h-text-primary;
}