@import '../../variables.module.scss';


// .navbarLogo{
//  // If needed we can overwrite styles from the Backend
//  width: 200px !important;
//  height: 53px !important;
//  min-width: unset  !important;
//  max-width: unset  !important;
//  min-height: unset  !important;
//  max-height:unset  !important;
// }

.navbar{
  // background-color: none;
  // background-color: rgba(188,143,51,1);
  // margin-top: 10px;
  padding: 0;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}

.navbarContainer{
  margin: 0;
  padding: 0;
  // height: 60px;
  position: relative;
 
}

// We need to override the link style
.navLink {
  color: $initial-navbar-link-color !important;
  position: relative;
  display: block;
  transition-duration: 0.2s;
  padding-left: 50px;
}
.navLink:hover {
  color: $initial-navbar-link-color-hover !important;
  text-decoration: underline;
}

.noNavLink{
  color: green;
}
.navDropdown{
  
  a {
    color: $initial-navbar-dropdown-title-link-color !important;
    position: relative;
    display: block;
    transition-duration: 0.2s;
  }
  a:hover{
    color: $initial-navbar-dropdown-link-color-hover !important;
    padding-left: 10px;
    transition-duration: 0.2s;
    background-color: $initial-navbar-dropdown-link-background-color-hover !important;
  }
  .navDropdownItem{    
      color: $initial-navbar-dropdown-link-color !important;    
  }
  .navDropdownItem:focus{    
    background-color: $initial-navbar-dropdown-link-color-focus !important;    
  }
  div{
    border:unset !important;
  } 
}
.toggle{
  border-color: white !important
}
.navbarHamburger {
  // background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 0.6)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  border-color: rgba(0, 0, 0, 0.1);
  border:none;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(154, 91, 165, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbarHamburger:hover {
  // background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0,0,0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}





// .navbar {
//   // width: 80%;
//   // margin: 0 auto;
//   // background: #fff;
//   // padding: 50px 0;
//   // box-shadow: 0px 5px 0px #dedede;
// }
// .navbar ul {
//   list-style: none;
//   text-align: center;
// }
// .navbar ul li {
//   display: inline-block;
// }
// .navbar ul li a {
//   display: block;
//   padding: 15px;
//   text-decoration: none;
//   color: #aaa;
//   font-weight: 800;
//   text-transform: uppercase;
//   margin: 0 10px;
// }
// .navbar ul li a,
// .navbar ul li a:after,
// .navbar ul li a:before {
//   transition: all .5s;
// }
// .navbar ul li a:hover {
//   color: #555;
// }

// /* stroke */
// .navLink ul li a,
// nav.fill ul li a {
//   position: relative;
// }
// .navLink ul li a:after,
// nav.fill ul li a:after {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin: auto;
//   width: 0%;
//   content: '.';
//   color: transparent;
//   background: #aaa;
//   height: 1px;
// }
// .navLink ul li a:hover:after {
//   width: 100%;
// }

// nav.fill ul li a {
//   transition: all 2s;
// }

// nav.fill ul li a:after {
//   text-align: left;
//   content: '.';
//   margin: 0;
//   opacity: 0;
// }
// nav.fill ul li a:hover {
//   color: #fff;
//   z-index: 1;
// }
// nav.fill ul li a:hover:after {
//   z-index: -10;
//   animation: fill 1s forwards;
//   -webkit-animation: fill 1s forwards;
//   -moz-animation: fill 1s forwards;
//   opacity: 1;
// }


/*************************
MOBILE - Navbar section start
*************************/
@media only screen and (max-width: 650px) {
  .navbarLogo{
    // If needed we can overwrite styles from the Backend
    width: 250px !important;
    height: 80px !important;
    min-width: unset  !important;
    max-width: unset  !important;
    min-height: unset  !important;
    max-height:unset  !important;
   }
   
  }
  /*************************
  MOBILE - Navbar section end
  *************************/