@import '../../variables.module.scss';
/*************************
DESKTOP - Footer section start
*************************/
.footer {  
  padding: 50px 0 100px 0;
  width: 100%;
  color: white;
  flex: 0 0 100px;/*or just height:50px;*/
  margin-top: auto;
  // text-align: center;
 }
 .socialLinkInner{ 
  width: 30px;
  height: 30px;  
  text-align: center; 
  position: absolute;
  top: 10%;
  color: $initial-social-link-icon-color;
  
}
.socialLinkInner:hover{
  color: $initial-social-link-icon-hovering-color;
}
.socialLinkOuter{
  width: 30px;
  height: 30px;
  border-radius: 5px;
  position: relative;
  background-color: $initial-social-link-bg-color;
}
.footer-social-list{
  display: flex;
  list-style-type: none;  
}
.footer-social-listitem{
  padding-right:10px;
}

.horizontal-links {
  text-align: center;
  ul {
    display: flex;
    list-style-type: none;
    li {
      padding-right: 35px;     
      &:last-child {
        padding-right: 0;
      }
      a {
        text-decoration: none;
      //     background-image: linear-gradient(90deg, $bg, $bg),
      //     linear-gradient(90deg, $underline-color, $underline-color);
      //   background-size: $underline-block-width $underline-width,
      //     100% $underline-width;
      //   background-repeat: no-repeat;
      //   background-position-x: calc($underline-block-width * -1), 0;
      //   background-position-y: 100%;
      //   transition: background-position-x $underline-transition;
      //     &:hover {         
      //       background-image: linear-gradient(90deg, $bg, $bg),
      // linear-gradient(
      //   90deg,
      //   $underline-color-hover,
      //   $underline-color-hover
      // );
      // background-position-x: calc(100% + $underline-block-width), 0;
      // color: yellow;
        // }       
        &:hover {
          text-decoration: underline;
        }
      }
      
    }
  }
}

.copyright-text{
  display: block;
  text-align: end;
  color: #82358B;
}

/*************************
DESKTOP - Footer section end
*************************/

/*************************
MOBILE - Footer section start
*************************/
@media only screen and (max-width: 600px) {
.horizontal-links {
  text-align: center;
  ul {
    display: flex;
    list-style-type: none;
    align-content: space-between;
    justify-content: center;
    padding-left: 0px;
  }
}
.copyright-text{
  display: block;
  text-align: center;
  color: #82358B;
}

}
/*************************
MOBILE - Footer section end
*************************/